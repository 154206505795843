import { __decorate, __extends, __read } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { systemModule } from '@/store/modules/system';
import { aboutModule } from '@/store/modules/about';
import { publicModule } from '@/store/modules/public';
var ContactInfo = (function (_super) {
    __extends(ContactInfo, _super);
    function ContactInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.currentID = 0;
        _this.activeName = '1';
        return _this;
    }
    ContactInfo.prototype.mounted = function () {
        aboutModule.getHeadquartersInfo();
        aboutModule.getContactInfo();
    };
    Object.defineProperty(ContactInfo.prototype, "lang", {
        get: function () {
            return publicModule.lang;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContactInfo.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContactInfo.prototype, "headquartersInfo", {
        get: function () {
            return aboutModule.headquartersInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContactInfo.prototype, "contactInfo", {
        get: function () {
            var _a;
            if (Object.keys(aboutModule.contactInfo).length) {
                _a = __read(Object.keys(aboutModule.contactInfo).map(function (item) { return parseInt(item, 10); }), 1), this.currentID = _a[0];
            }
            return aboutModule.contactInfo;
        },
        enumerable: false,
        configurable: true
    });
    ContactInfo.prototype.handleChange = function (event) {
        this.currentID = event.target.value;
    };
    ContactInfo.prototype.handleChangeID = function (id) {
        this.currentID = id;
    };
    ContactInfo = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], ContactInfo);
    return ContactInfo;
}(Vue));
export default ContactInfo;
